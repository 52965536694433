import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const LandTypesPage: FC = () => (
  <Layout>
    <SEO title="Landgerðir" />
    <h2>Landgerðir</h2>
    <p>
      Land er einungis flokkað í þrjár landgerðir og verður hver og einn notandi
      að meta sjálfur hvernig landið sem hann er að skoða skiptist í þessar
      landgerðir. Landgerðaflokkunin tekur mið af landgerðum í nýskógrækt þar
      sem munur er á mati á jafnaðarbindingu og losun.
    </p>
    <ul>
      <li>
        <b>Lítt gróið þurrlendi:</b> Þurrlendi sem er með gróðurþekju undir 20-30%.
        Oftast mjög rýrt land en kann að bera ágætan trjávöxt hjá trjátegundum
        sem þola að vaxa í slíku landi. Einungis þær trjátegundir sem við teljum
        að geti þrifist og vaxið eðlilega í þessu landi eru valkvæðar í
        kolefnisreikninum. Talið er að binding í jarðvegi sé nokkru meiri en við
        gróðursetningu í grónara og frjósamara þurrlendi. Gert er ráð fyrir sömu
        jarðvegsbindingu og áætluð er í landgræðslu, 1,881 tonni CO<sub>2</sub>{" "}
        á ha og ár.
      </li>
      <li>
        <b>Hálf- til fullgróið þurrlendi:</b> Land sem er með gróðurþekju yfir 20-30%.
        Afar misjafnt land hvað frjósemi varðar. Allt frá því að vera mjög rýrt
        land yfir í mjög frjósamt land og er vöxtur trjátegundanna eftir því.
        Allar trjátegundir eru valkvæðar hér ef vaxtarskilyrðin bjóða upp á það.
        Gert er ráð fyrir 1,340 tonnum CO<sub>2</sub> á ha og ár í bindingu í jarðvegi sem er
        byggt á innlendum rannsóknarniðurstöðum.
      </li>
      <li>
        <b>Framræst votlendi:</b> Hér er gert ráð fyrir losun CO<sub>2</sub> og þá bæði
        beinni losun vegna niðurbrots lífrænna efna í lífrænum jarðvegi og
        óbeinni losun vegna útskolunar uppleystra lífrænna efna úr jarðvegi.
        Notaðir eru alþjóðlegir stuðlar fyrir skóg og kjarrlendi á framræstu
        votlendi og er stuðullinn fyrir beina losun 1,357 tonn CO<sub>2</sub> á
        ha og ár en fyrir óbeina losun 0,440 tonn CO<sub>2</sub> á ha, samtals
        1,797 tonn CO
        <sub>2</sub> á ha og ár. Framræst votlendi losar líka metan (CH
        <sub>4</sub>) og hláturgas (N<sub>2</sub>O) og er sú losun metin 0,39
        tonn CO<sub>2</sub> – ígilda á ha og ár. Samtals er því losun frá
        jarðvegi í framræstu votlendi metin 2,187 tonn CO<sub>2</sub> – ígilda á
        ha og ár. Framræst votlendi er oftast frjósamt land og hentar því betur
        tegundum sem krefjast frjósemi. Framræst votlendi getur þó verið erfitt
        í ræktun bæði vegna samkeppni annars gróðurs og frosthættu á vaxtartíma
        enda er þessa landgerð oft að finna í litlum halla þar sem kalt loft
        getur safnast saman.
      </li>
    </ul>
  </Layout>
)

export default LandTypesPage
